import { jsx as _jsx } from "react/jsx-runtime";
import Link from '@components/shared/Link/Link';
import { helpServiceChat } from '@configs/common';
import { TariffPlanEnum } from '@store/models/subscription/types';
export const trialDays = 30;
export const minPaymentChangeDays = 29;
export const mtsJuniorRules = 'https://l.mts.ru/1/7XcIip';
export const supportLink = 'https://support.mts.ru/mts_junior';
export const siteLink = 'https://junior.mts.ru';
export const tariffInfoLink = 'https://support.mts.ru/mts_junior/Otveti-na-voprosi/kto-mozhet-podklyuchit-mts-junior';
export const paymentWidgetID = 'mts-payment-widget';
// убираем тип для работы со сторонними виджетами из объекта window
export const untypedWindow = window;
export const prodProfileUrl = 'https://identitystatic.mts.ru/eco-header/profile-widget-b2c/current/profile-widget.bundle.js';
export const stageProfileUrl = 'https://identitystatic.mts.ru/eco-header/profile-widget-b2c/stage/profile-widget-b2c.bundle.js';
export var Page;
(function (Page) {
    Page["Main"] = "/";
    Page["Child"] = "/child";
    Page["NotFound"] = "/404";
    Page["Instruction"] = "/instruction/:partner";
    Page["ChildInstruction"] = "/:version/instruction/:partner";
    Page["Pingo"] = "/gmd_promo";
    Page["PingoStory"] = "/gmd_promo/story/:id";
    Page["CreateSubscription"] = "/create-subscription";
    Page["CreateChildSubscription"] = "/child/create-subscription";
    Page["UpdateSubscription"] = "/update-subscription";
    Page["AddChild"] = "/add-child";
    Page["ChangeChild"] = "/change-child";
    Page["ChildInfo"] = "/child-info";
    Page["SuccessParentAdd"] = "/success-add";
    Page["SuccessChildAdd"] = "/child/success-add";
    Page["SuccessUpdate"] = "/success-update";
    Page["ParentSettings"] = "/parent-settings";
    Page["Promocode"] = "/promocode";
})(Page || (Page = {}));
export const pingoStoryUrl = `${Page.Pingo}/story`;
export const launcherSuccessUrl = 'https://s3.launcher.mts.ru/docs/ua_junior.html?status=success';
export const launcherFailUrl = 'https://s3.launcher.mts.ru/docs/ua_junior.html?status=false';
export const partnerUrlNameMap = {
    Pingo: 'pingo',
    LogicLike: 'logicLike',
    Kion: 'kion',
    Music: 'music',
    Leoni: 'leoni',
};
export const minYearDuration = 360; // не могут договориться, сколько дней в году 360 или 365
export const helpChatLink = (_jsx(Link, { href: helpServiceChat.href, inNewWindow: true, type: "primary", className: "noWrap", children: helpServiceChat.text }));
export const planInfoMap = {
    [TariffPlanEnum.Month]: {
        title: 'месяц',
        units: '₽',
        duration: 'мес',
        benefit: '1000 ₽/мес',
    },
    [TariffPlanEnum.Year]: {
        title: 'год',
        units: '₽',
        duration: 'год',
        benefit: '4 779 ₽/год',
    },
};
