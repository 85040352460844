import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classes from './Header.module.scss';
import Button from '@shared/Button/Button';
import { useWindowSize } from '@hooks/useWindowSize';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useSelector } from 'react-redux';
import { authUserSubscribeSelector } from '@models/selectors';
import { getSubscribeButtonText } from '@configs/subscription';
import ProductLogo from '@shared/ProductLogo/ProductLogo';
import { ProfileWidget } from './ProfileWidget/ProfileWidget';
import Spinner from '@shared/Spinner/Spinner';
import { subscriptionAvailabilitySelector, checkAvailabilityStatusSelector, availablePlanSelector, isRenewAvailableSelector, } from '@models/subscription/selectors';
import InvitationButton from './InvitationButton/InvitationButton';
import VersionButton from '@shared/VersionButton/VersionButton';
import { authSelector, childThemeSelector, childUserSelector, invitationShownSelector, loadingErrorSelector, } from '@store/models/user/selectors';
import { Page, supportLink } from '@const';
import { Link, useNavigate } from 'react-router-dom';
import { triggerMetricsEvent } from '@metrics';
const Header = ({ isLoading, isStatic, withoutVersionButton, stickyButtonConfig, withStickyButton = true }) => {
    const [onScrollSetted, setOnScroll] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const { isConnection: isAvailabilityChecking } = useSelector(checkAvailabilityStatusSelector);
    const isAuthUserSubscribe = useSelector((state) => authUserSubscribeSelector(state));
    const isSubscriptionAvailable = useSelector(subscriptionAvailabilitySelector);
    const isChildVersion = useSelector(childThemeSelector);
    const isChildUser = useSelector(childUserSelector);
    const { isMobileSm, isMobile, isDesktop } = useWindowSize();
    const isInvitation = useSelector(invitationShownSelector);
    const isAuth = useSelector(authSelector);
    const isLoadingError = useSelector(loadingErrorSelector);
    const { isFree } = useSelector(availablePlanSelector);
    const isRenewAvailable = useSelector(isRenewAvailableSelector);
    const { text: buttonText, link: createPage } = getSubscribeButtonText({ isChildVersion, isFree, isRenewAvailable });
    const isStickyButtonShown = withStickyButton && (isSubscriptionAvailable || stickyButtonConfig);
    const { userModel: { changeVersion }, } = useTypedDispatch();
    const navigate = useNavigate();
    const handleTryClick = () => {
        triggerMetricsEvent({ element: 'stickyButton' });
        navigate(createPage);
    };
    const handleScroll = useCallback(() => {
        if (isStatic) {
            return;
        }
        setIsScrolling(window.pageYOffset > 0);
    }, [isMobileSm, isStatic]);
    const handleLogoClick = () => {
        triggerMetricsEvent({ element: 'headerLogo' });
        if (isChildVersion && !isChildUser) {
            changeVersion({});
        }
    };
    useEffect(() => {
        if (!isMobileSm) {
            setIsScrolling(window.pageYOffset > 0);
            window.addEventListener('scroll', handleScroll);
            setOnScroll(true);
        }
        if (isMobileSm && onScrollSetted) {
            window.removeEventListener('scroll', handleScroll);
            setOnScroll(false);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobileSm, isStatic]);
    const staticBlockClasses = classNames(classes.profile, {
        [classes.subscribe]: isAuthUserSubscribe,
        [classes.blockDisabled]: isScrolling && !isMobileSm,
        [classes.authProfile]: isAuth,
    });
    const defaultStickyButtonProps = {
        variant: 'primary',
        size: 'md',
        full: isMobile,
        text: buttonText,
        isLoading: isAvailabilityChecking,
    };
    const stickyButtonProps = stickyButtonConfig
        ? { ...defaultStickyButtonProps, ...stickyButtonConfig }
        : { ...defaultStickyButtonProps, onClick: handleTryClick };
    const scrollBlock = isInvitation ? _jsx(InvitationButton, { ...defaultStickyButtonProps }) : _jsx(Button, { ...stickyButtonProps });
    const headerClasses = classNames(classes.header, { [classes.scroll]: isScrolling, [classes.static]: isStatic });
    const isVersionButton = !withoutVersionButton && !isMobile && !isLoading && !isLoadingError;
    return (_jsx("div", { className: classes.container, children: _jsxs("header", { className: headerClasses, children: [_jsx(Link, { to: Page.Main, onClick: handleLogoClick, children: _jsx(ProductLogo, {}) }), _jsxs("div", { className: classes.buttons, children: [isVersionButton && _jsx(VersionButton, { className: classes.versionButton }), isDesktop && !isScrolling && (_jsx(Button, { className: classes.support, variant: "transparent", size: "md", text: "\u041F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0430", link: supportLink, onOtherPage: true })), isLoading ? (_jsx("div", { className: classes.loadBlock, children: _jsx(Spinner, { color: "black", size: "sm", isAbsolutePos: false }) })) : (_jsx("div", { className: staticBlockClasses, children: _jsx(ProfileWidget, {}) }))] })] }) }));
};
export default Header;
