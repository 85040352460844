import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { helpChatLink } from '@const';
import SubscribeAddWrapper from '../SubscribeAddWrapper/SubscribeAddWrapper';
import Button from '@components/shared/Button/Button';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
const OTPCodeFailed = () => {
    const { subscriptionModel: { setAddChildStage }, dialogModel: { closeDialog }, } = useTypedDispatch();
    const handleClick = () => {
        setAddChildStage('enterNumber');
        closeDialog();
    };
    const title = _jsx("p", { children: "\u041D\u0435 \u043F\u0440\u0438\u0445\u043E\u0434\u0438\u0442 SMS \u0441\u00A0\u043A\u043E\u0434\u043E\u043C \u043D\u0430\u00A0\u043D\u043E\u043C\u0435\u0440 \u0420\u0435\u0431\u0451\u043D\u043A\u0430" });
    const content = _jsxs("p", { children: ["\u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u043D\u043E\u043C\u0435\u0440 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443: ", helpChatLink] });
    const action = _jsx(Button, { size: "md", variant: "primary", text: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043A \u043D\u043E\u043C\u0435\u0440\u0443", onClick: handleClick, full: true });
    return _jsx(SubscribeAddWrapper, { title: title, content: content, action: action });
};
export default OTPCodeFailed;
